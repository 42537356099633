<template>
  <environment-template
    :dryness-caused-by-climate="drynessCausedByClimate"
    :title="questionTitle"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import EnvironmentTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-dryness/environment/EnvironmentTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

const { requiredField, field } = fieldBuilder;

const FIELDS = [
  field('countryOfResidence'),
  field('cityOfResidence'),
  requiredField('drynessCausedByClimate')
];

export default {
  name: 'Environment',
  components: {
    EnvironmentTemplate
  },
  mixins: [makeStep(FIELDS)],
  computed: {
    questionTitle() {
      const city = `city.${this.countryOfResidence}.${this.cityOfResidence}`;

      return this.$i18n.t('label.drynessCausedByClimate', [`${city}`]);
    }
  },
  watch: {
    drynessCausedByClimate() {
      this.showNextStep();
    }
  }
};
</script>
